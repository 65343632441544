import { Layout, Switch } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { ROOT_TOPIC } from "../../utils/constants";
import { theme } from "../../utils/theme";
import { FulfillResponse } from "../../utils/types";
import {
    buildTileHierarchy,
    ChartTile,
    GoalChartContentBody,
    GridSwitcher,
} from "../countries/CountriesContent";
import { ContentCard, ExploreBtn, InvertableCard, TopicHeader } from "../shared/components";
import IndicatorOverview from "../shared/goals/IndicatorOverview";
import ViewIndex from "./ViewIndex";

const ExploreIndicator: React.FC<{
  fulfillResponse?: FulfillResponse;
  placeDcids: string[];
  // selectedVariableDcids: string[];
  isSearch?: boolean;
  hideGoalOverview?: boolean;
  hideTargetHeader?: boolean;
  blockID?: number | undefined;
  goal: string;
  target: string;
  indicator: string;
}> = ({ fulfillResponse, placeDcids, goal, target, indicator }) => {
  const mainTopicDcids =
    fulfillResponse?.relatedThings?.mainTopics?.map((e) => e.dcid) || [];
  const [showIndex, setShowIndex] = useState<boolean>(false);
  const [itemsToShow, setItemsToShow] = useState(10);
  const changeIndexView = (v: boolean) => setShowIndex(v);
  const location = useLocation()
  const blockID = location.hash?.replace("#",'')
  const isBlock = location.hash && blockID > -1
  const processedTiles =
    fulfillResponse?.config &&
    buildTileHierarchy(
        isBlock ? {...fulfillResponse?.config?.categories[0], blocks: [fulfillResponse?.config?.categories[0].blocks[blockID]]} : fulfillResponse?.config?.categories[0],
        mainTopicDcids,
        fulfillResponse.relatedThings.varToTopics
    );
  const blockTitle = isBlock && fulfillResponse?.config?.categories[0].blocks[blockID] && fulfillResponse?.config?.categories[0].blocks[blockID].title  
  const color = theme.sdgColors[Number(goal) - 1];
  const statVarSpec =
    fulfillResponse?.config.categories && fulfillResponse?.config.categories[0];
  const items =
    processedTiles &&
    processedTiles.hierarchy[goal] &&
    processedTiles.hierarchy[goal][target] &&
    processedTiles.hierarchy[goal][target][indicator];

  return (
    <IndicatorLayout id="top">
      <Layout.Content style={{ background: theme.searchBackgroundColor }}>
        <div className="head">
          <GridSwitcher>
            <Switch
              checked={showIndex}
              onChange={changeIndexView}
              size="small"
            />{" "}
            Data catalogue
          </GridSwitcher>
        </div>
        {!isBlock && showIndex ? (
          <ViewIndex
            fulfillResponse={fulfillResponse as FulfillResponse}
            buildTileHierarchy={buildTileHierarchy}
          />
        ) : (
          <>
            <InvertableCard className="-dc-goal-overview" color={color}>
              <TopicHeader color={color} target={`${indicator}`} />
            </InvertableCard>
            {!isBlock &&
            <IndicatorOverview
              showNotFound={true}
              limit={1}
              color={color}
              dcid={`${ROOT_TOPIC}_${indicator}`}
            />}
            {fulfillResponse &&
              items &&
              statVarSpec &&
              Object.values(items).slice(0, itemsToShow).map((item, i) => {
                return (
                  <GoalChartContentBody key={i}>
                    {isBlock && <ContentCard>{blockTitle}</ContentCard>}
                    <ContentCard>
                      <ChartTile
                        fulfillResponse={fulfillResponse}
                        key={`${indicator}-${i}`}
                        placeDcids={placeDcids}
                        tileWithFootnote={item}
                        statVarSpec={statVarSpec.statVarSpec}
                      />
                    </ContentCard>
                  </GoalChartContentBody>
                );
              })}
              {items && itemsToShow < items.length && (
                // Show "Load More" if there are more items to load
                <ExploreBtn>
                <button onClick={() => setItemsToShow(itemsToShow + 10)}>Explore more</button>
                </ExploreBtn>
            )}
          </>
        )}
      </Layout.Content>
    </IndicatorLayout>
  );
};
export default ExploreIndicator;

const IndicatorLayout = styled(Layout)`
  height: 100%;
  flex-grow: 1;
  > .ant-layout-content {
    padding: 0 24px 24px;
    > .head {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      padding: 0.75rem 0 1rem;
    }
  }
`;
