import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
//import { useStoreActions } from "../../state";
import styled from "styled-components";
//import topicsData from "../../config/sidebar_merged.json";
import WHORegions from "../../config/who_regions.json";
import DataCommonsClient from "../../utils/DataCommonsClient";
import { WEB_API_ENDPOINT } from "../../utils/constants";
import { ChartFootnote } from "../shared/components";

const DatacommonsMapContainer = styled.div`
  overflow: hidden;
  datacommons-slider::part(container) {
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid #e3e3e3;
    border-radius: 0;
    .chart-content {
      padding: 16px !important;
    }
  }
`;

const ChartContainer = styled.div`
  datacommons-map::part(source-separator) {
    display: none;
  }
  datacommons-map::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-line::part(source-separator) {
    display: none;
  }
  datacommons-line::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-bar::part(source-separator) {
    display: none;
  }
  datacommons-bar::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-highlight::part(source-separator) {
    display: none;
  }
  datacommons-highlight::part(source-show-metadata-link) {
    display: none;
  }
  datacommons-highlight::part(container) {
    margin: -24px !important;
    width: calc(100% + 48px);
    display: inline-block;
  }
`;

const Indicator: React.FC<{
  code: string;
  name: string;
  isTopic: boolean | undefined;
  limit: number | undefined;
  placeDcid: string | undefined;
  store: object | undefined;
  hideAlternates: boolean | undefined;
  setter: boolean | undefined;
}> = ({ code, name, isTopic, limit, placeDcid, store, hideAlternates, setter }) => {
  /*

1. Do a call to https://staging.unsdg.datacommons.org/api/variable/info?dcids=who/WHO_AIR_46 using indicator WHO_AIR_46 (as an example)
    if no data is returned (continue below)
2.  Do a call second call to https://staging.unsdg.datacommons.org/api/node/triples/in/WHO_AIR_46 which will return three statistical variables:

     who/AIR_46.ENVCAUSE--ENVCAUSE039
     who/AIR_46.ENVCAUSE--ENVCAUSE039__SEX--F
     who/AIR_46.ENVCAUSE--ENVCAUSE039__SEX--M

3. We will display those three stat vars in a single chart.

  */

  const [indicators, setIndicators] = useState(null);
  const [places, setPlaces] = useState([]);
  const [suggestedPlaces, setSuggestedPlaces] = useState([]);
  const [sources, setSources] = useState("");
  const VARIABLE_NAME_REGEX = "(?<=\\[)(.*?)(?=\\])";
  const DEFAULT_VARIABLE_NAME = "Total";
  const PLACE_NAME_PROP = "unDataLabel";
  const fullfilCalledRef = useRef(false);
  const [topics, setTopics] = useState([]);
  //const store = useStoreState((s) => s);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/config/sidebar_merged.json`)
      .then((response) => response.json())
      .then((data) => {
        setTopics(data.sort((a, b) => a.label.localeCompare(b.label)));
      })
      .catch((error) => console.error("Error loading sidebar_merged.json:", error));
  }, []);

  useEffect(() => {
    setIndicators(null);
    fullfilCalledRef.current = false;

    if (isTopic) {
      fullfil(code);
    } else {
      getInfo(code).then((res) => {
        if (typeof res[code] != "undefined") {
          const type = getType(res[code]);
          const topPlaces = res[code].placeTypeSummary[type].topPlaces.map((m) => m.dcid);
          fullfil(code, placeDcid ? [placeDcid] : topPlaces);
        } else {
          setIndicators([]);
        }

        /*getTriples(code).then((triples) => {
          if (typeof triples.measuredProperty !== "undefined") {
            triples.measuredProperty.forEach((element, index) => {
              fullfil(element.dcid);
            });
          } else {
            fullfil(code, placeDcid ? [placeDcid] : topPlaces);
          }
        });*/
      });
    }
  }, [code, placeDcid]);

  async function getInfo<T>(code: string): Promise<T> {
    const response = await fetch(`${WEB_API_ENDPOINT}/api/variable/info?dcids=${code}`);
    return await (response.json() as Promise<T>);
  }

  async function getTriples<T>(code: string): Promise<T> {
    const response = await fetch(`${WEB_API_ENDPOINT}/api/node/triples/out/${code}`);
    return await (response.json() as Promise<T>);
  }

  async function getExistence<T>(code, entities): Promise<T> {
    const c = code.replace(/topic/g, "g"); //dc/topic/UN_SUB_THEME_18
    const response = await fetch(`${WEB_API_ENDPOINT}/api/observation/existence`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        entities: entities,
        variables: [c],
      }),
    });
    return await (response.json() as Promise<T>);
  }

  const getTruthyPlaces = (code, list) => {
    let results = [];
    const c = code.replace(/topic/g, "g");
    const truthyKeys = Object.keys(list[c]).filter((key) => list[c][key] === true);
    truthyKeys.forEach((v, k) => {
      if (k <= 5) {
        results.push(v);
      }
    });
    //setPlaces(results);
    return results;
  };

  const fullfil = (code, entities) => {
    if (fullfilCalledRef.current) return;
    fullfilCalledRef.current = true;

    const placeList = entities ? entities : [placeDcid ? placeDcid : "Earth"];
    setPlaces(placeList);
    const client = new DataCommonsClient({ apiRoot: WEB_API_ENDPOINT });
    client
      .fulfill({
        dc: "undata",
        entities: placeList,
        variables: [code],
        childEntityType: "",
        comparisonEntities: [],
        comparisonVariables: [],
      })
      .then((v) => {
        // if the fullfil doesn't return any data for the topic. UN_SUB_THEME_27
        // try fetching with outher places
        if (v.showForm && !hideAlternates) {
          // console.log(store);
          const regions = store?.regions.dcids || [];
          const countries = store?.countries.dcids || [];
          const whoRegions = WHORegions.map((v) => v.dcid);

          getExistence(code, [...regions, ...countries, ...whoRegions]).then((res) => {
            const selectedPlaces = getTruthyPlaces(code, res);
            fullfilCalledRef.current = false; // Reset flag to allow recursive call
            fullfil(code, selectedPlaces);
          });
        } else {
          if (hideAlternates) {
            const regions = store?.regions.dcids;
            const countries = store?.countries.dcids;
            const whoRegions = WHORegions?.map((v) => v.dcid);
            getExistence(code, [...regions, ...countries, ...whoRegions]).then((res) => {
              const selectedPlaces = getTruthyPlaces(code, res);
              setSuggestedPlaces(selectedPlaces);
            });
          }

          setIndicators(getFullfillResponse(v, code));
        }
      });
  };

  const getFullfillResponse = (response, code) => {
    let results = [];
    if (typeof response.config.categories != "undefined") {
      if (typeof response.config.categories[0].blocks != "undefined") {
        results = prioritizeCharts(response.config.categories[0].blocks, response);
      }
    }
    return results;
  };

  const findStatVarKeys = (statVarKeys, statVarSpec) => {
    const results = [];
    statVarKeys.forEach((key) => {
      if (statVarSpec.hasOwnProperty(key)) {
        results.push(statVarSpec[key].statVar);
      }
    });
    return results;
  };

  const prioritizeCharts = (blocks, response) => {
    const charts = [];
    const highlights = [];
    blocks.forEach((element) => {
      if (typeof element.columns[0] != "undefined") {
        if (element.columns[0].tiles[0].type != "PLACE_OVERVIEW" && element.columns[0].tiles[0].type != "HIGHLIGHT") {
          element.columns[0].tiles[0].statVarKey = findStatVarKeys(element.columns[0].tiles[0].statVarKey, response.config.categories[0].statVarSpec);
          charts.push(element);
        }
      }
    });

    blocks.forEach((element) => {
      if (typeof element.columns[0] != "undefined") {
        if (element.columns[0].tiles[0].type == "HIGHLIGHT") {
          element.columns[0].tiles[0].statVarKey = findStatVarKeys(element.columns[0].tiles[0].statVarKey, response.config.categories[0].statVarSpec);
          highlights.push(element);
        }
      }
    });
    return [...charts, ...highlights];
  };

  const renderChart = (type, data, tile) => {
    const footnote = "This chart incorporates various dates to create the most relevant visual. " + (data.footnote ? data.footnote : "");
    const foundIndicator = findIndicatorByCode(data.columns[0].tiles[0].statVarKey[0], topics);

    // grab the source and pass it in as a sources sttribute to widgets
    /*getTriples(data.columns[0].tiles[0].statVarKey[0]).then((triples) => {
      if (typeof triples.populationType !== "undefined") {
        getTriples(triples.populationType[0].dcid).then((triples2) => {
          if (typeof triples2.source !== "undefined") {
            setSources(triples2.source[0].value);
          }
        });
      }

      if (typeof triples.source !== "undefined") {
        setSources(triples.source[0].value);
      }
    });*/

    switch (type) {
      case "MAP":
        const channel = `map-${data.columns[0].tiles[0].statVarKey[0]}`;
        return (
          <DatacommonsMapContainer>
            <datacommons-map
              sources={foundIndicator ? foundIndicator.indicator_source : undefined}
              subscribe={channel}
              apiRoot={WEB_API_ENDPOINT}
              header={tile.title}
              variable={data.columns[0].tiles[0].statVarKey[0]}
              parentPlace={places.join(" ")}
              childPlaceType="Country"
              showexploremore="false"
            >
              <div style={{ borderRadius: 0 }} slot="footer">
                <datacommons-slider
                  apiRoot={WEB_API_ENDPOINT}
                  publish={channel}
                  variable={data.columns[0].tiles[0].statVarKey[0]}
                  parentPlace={places.join(" ")}
                  childPlaceType="Country"
                />
                <ChartFootnote text={footnote} />
              </div>
            </datacommons-map>
          </DatacommonsMapContainer>
        );
        break;

      case "BAR":
        const barchannel = `bar-${data.columns[0].tiles[0].statVarKey[0]}`;
        return (
          <datacommons-bar
            apiRoot={WEB_API_ENDPOINT}
            subscribe={barchannel}
            sources={foundIndicator ? foundIndicator.indicator_source : undefined}
            header={tile.title}
            variables={tile.statVarKey.join(" ")}
            places={places.map((element) => element.replace(/,/g, "_")).join(" ")}
            showexploremore="false"
            placenameprop="unDataLabel"
            date="HIGHEST_COVERAGE"
          >
            <div slot="footer">
              <datacommons-slider
                apiRoot={WEB_API_ENDPOINT}
                publish={barchannel}
                places={places.map((element) => element.replace(/,/g, "_")).join(" ")}
                variables={data.columns[0].tiles[0].statVarKey[0]}
                date="HIGHEST_COVERAGE"
              />
              <ChartFootnote text={data.footnote ? data.footnote : ""} />
            </div>
          </datacommons-bar>
        );
        break;

      case "LINE":
        return (
          <datacommons-line
            apiRoot={WEB_API_ENDPOINT}
            sources={foundIndicator ? foundIndicator.indicator_source : undefined}
            header={tile.title}
            variables={tile.statVarKey.join(" ")}
            places={places.join(" ")}
            variableNameRegex={VARIABLE_NAME_REGEX}
            showExploreMore={false}
            defaultVariableName={DEFAULT_VARIABLE_NAME}
            timeScale="year"
            placeNameProp={PLACE_NAME_PROP}
          />
        );
        break;

      case "HIGHLIGHT":
        return (
          <>
            {/** @ts-ignore */}
            <datacommons-highlight
              sources={foundIndicator ? foundIndicator.indicator_source : undefined}
              apiRoot={WEB_API_ENDPOINT}
              header={tile.title}
              variable={tile.statVarKey[0]}
              place={places.join(" ")}
            />
          </>
        );
        break;
    }
  };

  const getType = (data) => {
    if (typeof data.placeTypeSummary.GeoRegion != "undefined") {
      return "GeoRegion";
    }

    if (typeof data.placeTypeSummary.Country != "undefined") {
      return "Country";
    }

    return null;
  };

  const Spinner: React.FC<{ fontSize?: string }> = ({ fontSize }) => {
    const DEFAULT_SPINNER_FONT_SIZE = "1.5rem";
    return (
      <div style={{ display: "block", textAlign: "center" }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: fontSize || DEFAULT_SPINNER_FONT_SIZE }} spin />} />
      </div>
    );
  };

  const getCountryLabel = (id) => {
    if (store.countries.byDcid[id]) {
      return store.countries.byDcid[id].name;
    }
    if (store.regions.byDcid[id]) {
      return store.regions.byDcid[id].name;
    }
  };

  const findIndicatorByCode = (code, data) => {
    for (let i = 0; i < data.length; i++) {
      const indicator = data[i];
      if (indicator.indicator_codes && indicator.indicator_codes.includes(code)) {
        return indicator;
      }
      if (indicator.children && indicator.children.length > 0) {
        const childIndicator = findIndicatorByCode(code, indicator.children);
        if (childIndicator) {
          return childIndicator;
        }
      }
    }
    return null; // Indicator not found
  };

  return (
    <>
      {indicators && indicators.length === 0 && (
        <>
          {(setter = true)}
          <span className="no-data-class">
            There is no data for "{getCountryLabel(placeDcid)}" on this indicator. Please try another country or area, or another indicator.
            {/*suggestedPlaces && (
            <small style={{ display: "block", padding: "1rem 0", fontStyle: "italic", fontWeight: "bold" }}>
              Suggested example(s):{" "}
              {suggestedPlaces.map((s) => {
                return getCountryLabel(s) + "; ";
              })}
            </small>
            )*/}
          </span>
        </>
      )}

      {!indicators ? (
        <Spinner />
      ) : (
        indicators.map((item, index) => {
          if (index + 1 <= limit) {
            //item.columns[0].tiles[0].statVarKey[0]
            return (
              <Row key={index} style={{ padding: "1rem" }} gutter={32}>
                <Col className="-dc-indicator-chart" span={24}>
                  {item.columns.map((i) => {
                    {
                      return i.tiles.map((v) => {
                        return (
                          <div style={{ backgroundColor: "#fff", borderRadius: "8px", padding: "0" }}>
                            <ChartContainer>{renderChart(v.type, item, v)}</ChartContainer>
                          </div>
                        );
                      });
                    }
                  })}
                </Col>
              </Row>
            );
          }
        })
      )}
    </>
  );
};

export default Indicator;
